export default {
    landPage: {
        text1: 'ฉลองการเปิดตัวแอป BXTEN บน Android',
        text2: 'กิจกรรมสมัครใหม่!',
        text3: 'Bonus 880USDT',
        text4: 'รับรางวัลตามปริมาณการซื้อขาย!',
        text5: 'รับโบนัส USDT และโทเค็น BXT เพียงแค่สมัครและทำ KYC ให้เสร็จสิ้น!',
        text6: 'ข้อได้เปรียบของ BXTEN',
        text7: 'จุดแข็ง 1',
        text8: '1. รองรับ Cobo Wallet เช่นเดียวกับ Binance',
        text9: '2. ให้การปกป้องสินทรัพย์ลูกค้าและความเสถียรในระดับสูงสุด',
        text10: 'จุดแข็ง 2',
        text11: '1. ระบบเครื่องมือและสภาพคล่องที่เหมาะสมสำหรับการซื้อขายฟิวเจอร์ส',
        text12: '2. สร้างขึ้นเฉพาะสำหรับการซื้อขายสกุลเงินดิจิทัล',
        text13: 'จุดแข็ง 3',
        text14: '1. แพลตฟอร์มแลกเปลี่ยนที่ผสานกับ AI อย่างมีนวัตกรรม',
        text15: '2. สนับสนุนการแปลภาษาทั่วโลกด้วย Chat GPT',
        text16: 'Mining Benefits with',
        text17: 'สิทธิประโยชน์จากการขุดโทเค็น BXT ตามปริมาณการซื้อขาย!',
        text18: 'การซื้อขายแบบ Spot',
        text19: 'สำหรับทุก ๆ ปริมาณการซื้อขาย 1,000 USDT รับแอร์ดรอป <span>1 BXT</span>',
        text20: 'Receive <span>1 BXT</span>!',
        text21: 'การซื้อขายแบบ Futures',
        text22: 'สำหรับทุก ๆ ปริมาณการซื้อขาย 1,000 USDT รับแอร์ดรอป <span>0.4 BXT</span>',
        text23: 'Receive <span>0.4 BXT</span>!',
        text24: 'โปรโมชั่นแอร์ดรอป BXT ปริมาณสูงนี้สามารถใช้ได้จนกว่า BXT จะเข้าจดทะเบียนอย่างเป็นทางการ!',
        text25: 'เริ่มทำการซื้อขายตอนนี้และรับ BXT!',
        text26: 'KYC BXTEN แบบง่ายด้วย SumSub',
        text27: 'วิธีการทำ KYC ให้สมบูรณ์',
        text28: '1. สมัคร (อีเมลหรือโทรศัพท์)',
        text29: 'เสร็จสิ้นการลงทะเบียนภายใน 10 วินาที!',
        text30: '2. ดำเนินการ KYC และส่งเอกสารยืนยัน',
        text31: 'KYC ที่รวดเร็วและง่ายดายด้วย SumSub',
        text32: '3. รับสิทธิประโยชน์สำหรับผู้ใช้ใหม่!',
        text33: 'ตรวจสอบโบนัส USDT ของคุณในส่วนของขวัญต้อนรับหลังจากการลงทะเบียนและการทำ KYC',
        text34: 'คุณสมบัติหลักของ BXTEN',
        text35: 'การปฏิบัติตามกฎระเบียบระดับโลก',
        text36: 'มีใบอนุญาต CMC และ MBS',
        text37: 'จดทะเบียนอย่างเป็นทางการกับ CMC ทั่วโลก',
        text38: 'มีใบอนุญาต MBS ของสหรัฐอเมริกา',
        text39: 'การรับรอง VASP',
        text40: 'ผู้ให้บริการสินทรัพย์เสมือนในโปแลนด์',
        text41: 'การลงทะเบียน VASP อยู่ในระหว่างดำเนินการ',
        text42: 'การซื้อขาย Spot',
        text43: '$2B+',
        text44: 'ปริมาณการซื้อขาย Spot รายวันมากกว่า 2 พันล้านดอลลาร์',
        text45: 'Over 2 Billion Dollars',
        text46: 'การซื้อขาย Futures',
        text47: '$20B+',
        text48: 'ปริมาณการซื้อขาย Futures รายวันมากกว่า 20 พันล้านดอลลาร์',
        text49: 'Over 20 Billion Dollars',
        text50: 'สำนักงานใหญ่ทั่วโลก',
        text51: '10+',
        text52: 'สาขาทั่วโลก',
        text53: 'บริการสนับสนุนลูกค้า 24/7',
        text54: 'เพลิดเพลินกับการสนับสนุนที่สะดวกสบายทุกเวลา ด้วยแชทบอท AI ของเรา!',
        text55: 'หากเกิดปัญหา จะมีเจ้าหน้าที่เฉพาะทางเพื่อช่วยแก้ไขอย่างรวดเร็ว!',
        text56:'Sign Up'
      }
  }
  