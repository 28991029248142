export default {
    landPage: {
        text1: 'Dalam Rangka Peluncuran Aplikasi BXTEN Android',
        text2: 'Acara Pendaftaran Baru!',
        text3: 'Bonus 880USDT',
        text4: 'Hadiah Berdasarkan Volume Perdagangan!',
        text5: 'Dapatkan Bonus USDT dan Token BXT hanya dengan Mendaftar dan Menyelesaikan KYC!',
        text6: 'Keunggulan Kompetitif BXTEN',
        text7: 'Kekuatan 1',
        text8: '1. Mendukung Cobo Wallet, mirip dengan Binance',
        text9: '2. Menyediakan perlindungan aset pelanggan dan stabilitas tingkat tertinggi',
        text10: 'Kekuatan 2',
        text11: '1. Mesin dan likuiditas yang dioptimalkan untuk perdagangan futures',
        text12: '2. Dibangun khusus untuk perdagangan mata uang kripto',
        text13: 'Kekuatan 3',
        text14: '1. Platform pertukaran inovatif dengan integrasi AI',
        text15: '2. Mendukung terjemahan bahasa global dengan Chat GPT',
        text16: '',
        text17: 'Hadiah Penambangan Token BXT Berdasarkan Volume Perdagangan!',
        text18: 'Perdagangan Spot',
        text19: 'Untuk setiap volume perdagangan 1,000 USDT, terima airdrop sebesar <span>1 BXT</span>',
        text20: 'Receive <span>1 BXT</span>!',
        text21: 'Perdagangan Futures',
        text22: 'Untuk setiap volume perdagangan 1,000 USDT, terima airdrop sebesar <br/><span>0.4 BXT</span>',
        text23: 'Receive <span>0.4 BXT</span>!',
        text24: 'Promosi airdrop BXT dengan jumlah besar ini tersedia hingga BXT terdaftar resmi!',
        text25: 'Mulai berdagang sekarang dan dapatkan BXT!',
        text26: 'KYC BXTEN yang Dipermudah dengan SumSub',
        text27: 'Cara Melengkapi KYC',
        text28: '1. Daftar (Email atau Nomor Telepon)',
        text29: 'Selesaikan pendaftaran hanya dalam 10 detik!',
        text30: '2. Lanjutkan KYC dan Kirimkan Dokumen Verifikasi',
        text31: 'KYC yang cepat dan mudah melalui SumSub',
        text32: '3. Dapatkan Manfaat Pengguna Baru!',
        text33: 'Cek Bonus USDT Anda di bagian Hadiah Selamat Datang setelah pendaftaran dan penyelesaian KYC',
        text34: 'Fitur Utama BXTEN',
        text35: 'Kepatuhan Regulasi Global',
        text36: 'Berlisensi CMC dan MBSe',
        text37: 'Terdaftar Resmi di CMC Global',
        text38: 'Memiliki Lisensi MBS AS',
        text39: 'Sertifikasi VASP',
        text40: 'Penyedia Layanan Aset Virtual di Polandia',
        text41: 'Pendaftaran VASP dalam Proses',
        text42: 'Perdagangan Spot',
        text43: '$2B+',
        text44: 'Volume perdagangan spot harian lebih dari $2 miliar',
        text45: 'Over 2 Billion Dollars',
        text46: 'Perdagangan Futures',
        text47: '$20B+',
        text48: 'Volume perdagangan futures harian lebih dari $20 miliar',
        text49: 'Over 20 Billion Dollars',
        text50: 'Kantor Pusat Global',
        text51: '10+',
        text52: 'Cabang Global',
        text53: 'Layanan Dukungan Pelanggan 24/7',
        text54: 'Nikmati dukungan yang nyaman kapan saja dengan chatbot berbasis AI kami!',
        text55: 'Jika ada masalah, perwakilan khusus akan ditugaskan untuk menyelesaikannya dengan cepat!',
        text56:'Sign Up'
      }
  }
  