export default {
    landPage:{
        text1:'BXTEN 안드로이드 앱 런칭 기념',
        text2:'최대 혜택 신규가입 이벤트!',
        text3:'880 USDT Bonus',
        text4:'선물 거래량에 따라 증정금 지급!',
        text5:'가입 및 KYC만 진행해도 USDT Bonus 및 BXT 토큰 지급!',
        text6:'BXTEN 거래소의 차별성',
        text7:'강점 1',
        text8:'1.바이낸스와 동일한 Cobo Wallet 지원',
        text9:'2.최고 레벨의 고객 자산 보호 및 안정성',
        text10:'강점 2',
        text11:'1.선물 거래의 최적화된 엔진과 유동성',
        text12:'2.암호화폐 트레이딩을 위한 시스템 구축',
        text13:'강점 3',
        text14:'1.혁신적인 AI 시스템을 도입한 거래소 플랫폼',
        text15:'2.Chat GPT를 이용한 전세계 언어 번역 지원(한국어 포함)',
        text16:'거래량에 따라 지급받는',
        text17:'<span>BXT</span> 토큰 마이닝 혜택!',
        text18:'현물 거래',
        text19:'1000 USDT 거래량 달성!<br/><span>1 BXT</span> 증정!',
        text20:'<span>1 BXT</span> 증정!',
        text21:'선물 거래',
        text22:'1000 USDT 거래량 달성!<br/><span>0.4 BXT</span> 증정!',
        text23:'<span>0.4 BXT</span> 증정!',
        text24:'BXT 상장 전까지 프로모션으로 진행되는 높은 수량의 BXT 에어드롭!',
        text25:'지금 바로 거래하고 BXT를 획득하세요!',
        text26:'SumSub 도입으로 편리해진 BXTEN KYC',
        text27:'KYC 진행방법',
        text28:'거래소 가입하기 (이메일 또는 휴대폰)',
        text29:'10초만에 가입 완료!',
        text30:'KYC 진행 및 증빙자료 제출',
        text31:'SumSub을 통한 빠르고 간편한 KYC',
        text32:'신규 혜택 받기!',
        text33:'Welcome Gift에서 가입 및 KYC에 따라 지급받은 USDT Bonus 확인하기',
        text34:'BXTEN 주요 특징',
        text35:'글로벌 규제 준수',
        text36:'CMC 및 MBS 라이센스',
        text37:'글로벌 CMC 정식 등록',
        text38:'미국 MBS 라이센스 보유',
        text39:'VASP 인증',
        text40:'폴란드 가상 자산 서비스 제공업체',
        text41:'VASP 등록 진행 중',
        text42:'현물',
        text43:'$2B+',
        text44:'일일 현물 거래량 20억 달러 이상',
        text45:'20억 달러 이상',
        text46:'선물',
        text47:'$20B+',
        text48:'일일 선물 거래량 200억 달러 이상',
        text49:'200억 달러 이상',
        text50:'글로벌 HQ',
        text51:'10+',
        text52:'글로벌 지사',
        text53:'연중무휴 24시간 고객 지원 서비스',
        text54:'AI 기반 챗봇으로 언제든지 편리한 지원을 받아보세요!',
        text55:'문제 발생 시 전담 담당자가 배정되어 신속하게 해결해드립니다!',
        text56:'회원가입'
      }
  }
  