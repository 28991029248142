export default {
    landPage: {
        text1: 'In Celebration of the BXTEN Android App Launch',
        text2: 'New Sign-Up Event! With Maximum Benefits!',
        text3: '880 USDT Bonus',
        text4: 'Reward Given Based on Trading Volume!',
        text5: 'Receive a USDT Bonus and BXT Tokens just for Signing Up and Completing KYC!',
        text6: 'BXTEN Comparative Advantage',
        text7: 'Strength 1',
        text8: '1. Supports Cobo Wallet, similar to Binance',
        text9: '2. Provides top-level customer asset protection and stability',
        text10: 'Strength 2',
        text11: '1. Optimized engine and liquidity for futures trading',
        text12: '2. Built specifically for cryptocurrency trading',
        text13: 'Strength 3',
        text14: '1. Innovative exchange platform with AI integration',
        text15: '2. Global language translation support with Chat GPT',
        text16: '',
        text17: 'BXT Token Mining Rewards Based on Trading Volume!',
        text18: 'Spot Trading',
        text19: 'For every 1,000 USDT in trading volume, receive an airdrop of <span>1 BXT</span>',
        text20: 'Receive <span>1 BXT</span>!',
        text21: 'Futures Trading',
        text22: 'For every 1,000 USDT in trading volume, receive an airdrop of <span>0.4 BXT</span>',
        text23: 'Receive <span>0.4 BXT</span>!',
        text24: 'This high-volume BXT airdrop promotion is available until the official BXT listing!',
        text25: 'Start Trading Now and Earn BXT!',
        text26: 'Simplified BXTEN KYC with SumSub',
        text27: 'How to Complete KYC',
        text28: '1.Sign Up (Email or Phone)',
        text29: 'Complete registration in just 10 seconds!',
        text30: 'Proceed with KYC and Submit Verification Documents',
        text31: 'Fast and easy KYC through SumSub',
        text32: 'Receive New User Benefits!',
        text33: 'Check your USDT Bonus in the Welcome Gift section after registration and KYC completion',
        text34: 'Key Features of BXTEN',
        text35: 'Global Regulatory Compliance',
        text36: 'CMC & MBS License',
        text37: 'Officially registered on global CMC',
        text38: 'Holds US MBS License',
        text39: 'VASP Certification',
        text40: 'Polish Virtual Asset Service Provider',
        text41: 'VASP Registration in Progress',
        text42: 'Spot',
        text43: '$2B+',
        text44: 'Over $2 billion in daily spot trading volume',
        text45: 'Over 2 Billion Dollars',
        text46: 'Futures',
        text47: '$20B+',
        text48: 'Over $20 billion in daily futures trading volume',
        text49: 'Over 20 Billion Dollars',
        text50: 'Global HQ',
        text51: '10+',
        text52: 'Global Branches',
        text53: '24/7 Customer Support Service',
        text54: 'Enjoy convenient support anytime with our AI-based chatbot!',
        text55: 'In case of any issues, a dedicated representative will be assigned to resolve them quickly!',
        text56:'Sign Up'
      }
  }
  