import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

// 各个国家的key
const localeKeys = ['en_US', 'ko_KR','id_ID','ti_TA']

// 各个国家语言包
const messages = {}
for (const key of localeKeys) {
  messages[key] = require(`./${key}.js`).default
}

export default new VueI18n({
  locale: 'en_US',
  messages,
  silentTranslationWarn: true // 忽略翻译警告
})
